<script setup>
import ModalButton from "../buttons/ModalButton.vue";
</script>

<template>
  <ModalButton class="bg-black text-white hover:bg-gray-300 hover:text-black">
    <slot />
  </ModalButton>
</template>

<script>
export default {
  components: {
    ModalButton,
  },
};
</script>
