<script setup>
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import BlackBorderBlackButton from "../components/buttons/Black-BorderBlackButton.vue";
</script>

<template>
    <BlockSectionTop/>
  <BlockSection>
    <Container>
      <div class="mx-2 flex flex-row justify-center">
        <div class="basis-full px-2 text-center lg:basis-8/12 xl:basis-6/12">
          <img
            src="/img/404.webp"
            alt="Not Found Page Polyus Studio"
            class="mb-12 h-auto w-full lg:mb-6 xl:mb-8"
          />
          <router-link to="/">
            <BlackBorderBlackButton>
              {{ $t("buttons.go_home") }}
            </BlackBorderBlackButton>
          </router-link>
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script>
export default {
  components: {
    BlockSection,
    Container,
    BlackBorderBlackButton,
  },
};
</script>

