<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    class="group-hover:fill-gray-300 duration-300"
      d="M8.7706 5.83008C5.54913 5.83008 2.93726 8.44195 2.93726 11.6634V23.3301C2.93726 26.5515 5.54913 29.1634 8.7706 29.1634H26.2706C29.4921 29.1634 32.104 26.5515 32.104 23.3301V11.6634C32.104 8.44195 29.4921 5.83008 26.2706 5.83008H8.7706ZM8.7706 8.74674H26.2706C27.7406 8.74674 28.9554 9.82592 29.1567 11.2405C27.7596 12.5428 25.6829 14.1411 24.3106 15.1269C21.1533 17.3947 18.3752 18.9551 17.5206 18.9551C16.666 18.9551 13.8879 17.3961 10.7306 15.1269C9.3583 14.1411 8.00936 13.0751 6.9929 12.2103C6.55977 11.8413 6.23892 11.5438 5.991 11.2988C6.19225 9.88424 7.3006 8.74674 8.7706 8.74674ZM5.86852 15.0963C9.56978 18.0903 14.9933 21.8542 17.5206 21.8717C19.1671 21.8834 21.9831 20.3113 24.856 18.3265C26.3421 17.2984 27.9958 16.0778 29.1888 15.0759L29.1873 23.3301C29.1873 24.9415 27.8821 26.2467 26.2706 26.2467H8.7706C7.15914 26.2467 5.85393 24.9415 5.85393 23.3301L5.86852 15.0963Z"
      fill="black"
    />
  </svg>
</template>
