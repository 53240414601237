<script setup>
import Container from "../components/shared/Container.vue";
import YellowBorderWhiteButton from "../components/buttons/Yellow-BorderWhiteButton.vue";
import Paragraph2 from "../components/text/Paragraph2.vue";
import Paragraph3 from "../components/text/Paragraph3.vue";
</script>

<template>
  <div
    v-if="showCookieConsent"
    class="fixed inset-x-0 bottom-0 z-[9999999] bg-black text-white opacity-85"
  >
    <Container>
      <div class="mx-2 flex flex-row flex-wrap items-center py-3">
        <div class="mb-3 basis-full px-2 lg:mb-0 lg:basis-10/12">
          <p>
            <Paragraph2>
              <Paragraph3>
                {{ $t("cookie.title") }}
              </Paragraph3>
              {{ $t("cookie.description") }}
            </Paragraph2>
            <router-link
              to="/legal/privacy-policy"
              exact
              class="ps-2 text-yellow-500 hover:underline"
            >
              <Paragraph3>
                {{ $t("footer.privacy_policy") }}
              </Paragraph3>
            </router-link>
          </p>
        </div>
        <div class="basis-full px-2 text-end lg:basis-2/12">
          <YellowBorderWhiteButton class="mb-4 me-4 sm:mb-0 sm:me-0">
            <button @click="acceptCookies">
              {{ $t("forms.submit") }}
            </button>
          </YellowBorderWhiteButton>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showCookieConsent: false,
    };
  },
  created() {
    this.checkCookieConsent();
  },
  methods: {
    checkCookieConsent() {
      const consent = this.$cookies.get("cookie-consent");
      if (!consent) {
        this.showCookieConsent = true;
      }
    },
    acceptCookies() {
      this.$cookies.set("cookie-consent", "accepted", "30d");
      this.showCookieConsent = false;
    },
  },
};
</script>
