<style>
.logo-hover-style {
  transition-duration: 0.2s;
}

.logo-hover-style:hover .path-fill-color {
  fill: black !important;
  transition-duration: 0.4s;
}

.logo-hover-style:hover .path-stroke {
  stroke: black !important;
  transition-duration: 0.4s;
}

@media screen and (max-width: 767px) {
  .logo-hover-style .black-logo-fill {
    fill: white !important;
  }
  .logo-one-el {
    stroke: #ffffff;
  }
  .logo-two-el {
    stroke: #f6f930;
  }

  .logo-hover-style:hover .logo-one-el {
    stroke: #f6f930 !important;
  }

  .logo-hover-style:hover .logo-two-el {
    stroke: #f6f930 !important;
  }

  .logo-hover-style:hover .path-fill-color {
    fill: #f6f930 !important;
  }
}
</style>

<template>
  <svg
    viewBox="0 0 120 59"
    fill="none"
    class="logo-hover-style md:h-[59px] h-[40px] w-auto"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_389_437)">
      <path
        d="M2.30774 30.9063L49.3453 2.69497C50.1806 2.19436 51.2226 2.20827 52.0459 2.72973L80.2962 20.6977C81.9077 21.7232 81.9047 24.0942 80.2924 25.1162L52.8399 42.5071C51.9632 43.0616 50.8437 43.0373 49.9928 42.4428L25.0428 27.9513"
        stroke="black"
        class="path-stroke logo-one-el"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M77.7654 37.4664L98.1893 24.5289C99.39 23.7693 99.3916 22.005 98.1931 21.2419L77.1731 7.875M96.3669 37.4664L116.791 24.5289C117.992 23.7693 117.993 22.005 116.795 21.2419L95.7746 7.875"
        stroke="#F6F930"
        class="path-stroke logo-two-el"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
      />
      <path
        d="M46.9456 54.9648C46.9456 55.9608 46.1714 56.4588 44.6229 56.4588H41.5758V57.8986C41.5758 58.1153 41.439 58.2237 41.1651 58.2237C40.9012 58.2237 40.7693 58.1153 40.7693 57.8986V53.0992C40.7693 52.9185 40.8838 52.8282 41.1128 52.8282H44.6229C46.1714 52.8282 46.9456 53.3263 46.9456 54.3223V54.9648ZM41.5758 55.824H44.6454C45.6411 55.824 46.139 55.5298 46.139 54.9415V54.3455C46.139 53.7571 45.6436 53.463 44.6528 53.463H41.6356C41.5957 53.463 41.5758 53.4811 41.5758 53.5172V55.824Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M53.3539 54.3223C53.3539 53.7494 52.9008 53.463 51.9946 53.463H50.359C49.4529 53.463 48.9999 53.7494 48.9999 54.3223V56.6755C48.9999 57.2587 49.4579 57.5503 50.374 57.5503H51.9797C52.8958 57.5503 53.3539 57.2587 53.3539 56.6755V54.3223ZM50.3815 58.185C48.9226 58.185 48.1932 57.6896 48.1932 56.6987V54.3067C48.1932 53.3211 48.9102 52.8282 50.3441 52.8282H52.0095C53.4435 52.8282 54.1604 53.3211 54.1604 54.3067V56.6987C54.1604 57.6896 53.431 58.185 51.9722 58.185H50.3815Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M57.7992 58.1849C56.3403 58.1849 55.611 57.6792 55.611 56.6677V53.1146C55.611 52.8978 55.7429 52.7894 56.0068 52.7894C56.2806 52.7894 56.4175 52.8978 56.4175 53.1146V56.6445C56.4175 57.2483 56.8905 57.5502 57.8365 57.5502H60.9284C61.1774 57.5502 61.3018 57.656 61.3018 57.8675C61.3018 58.0792 61.1774 58.1849 60.9284 58.1849H57.7992Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M63.5874 55.8394V57.8985C63.5874 58.1153 63.4531 58.2236 63.1842 58.2236C62.9104 58.2236 62.7734 58.1153 62.7734 57.8985V55.8394L60.3313 53.3855C60.2865 53.3442 60.2517 53.3055 60.2267 53.2693C60.2068 53.2281 60.1969 53.1791 60.1969 53.1223C60.1969 53.0191 60.2342 52.9391 60.3089 52.8824C60.3836 52.8204 60.4857 52.7894 60.6151 52.7894C60.6947 52.7894 60.7595 52.8023 60.8093 52.8282C60.8591 52.8539 60.9114 52.8952 60.9661 52.952L63.1842 55.2278L65.4097 52.9442C65.4595 52.8927 65.5093 52.8539 65.5591 52.8282C65.6139 52.8023 65.6836 52.7894 65.7682 52.7894C65.8827 52.7894 65.9774 52.8204 66.052 52.8824C66.1267 52.9391 66.1641 53.0191 66.1641 53.1223C66.1641 53.1791 66.1516 53.2281 66.1267 53.2693C66.1018 53.3055 66.0694 53.3442 66.0296 53.3855L63.5874 55.8394Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M69.4535 58.1849C67.9947 58.1849 67.2653 57.6792 67.2653 56.6677V53.1146C67.2653 52.8978 67.3997 52.7894 67.6686 52.7894C67.9374 52.7894 68.0719 52.8978 68.0719 53.1146V56.6445C68.0719 57.2483 68.5449 57.5502 69.4908 57.5502H71.1189C72.0599 57.5502 72.5304 57.2483 72.5304 56.6445V53.1146C72.5304 52.8978 72.6673 52.7894 72.9412 52.7894C73.21 52.7894 73.3445 52.8978 73.3445 53.1146V56.6677C73.3445 57.6792 72.6151 58.1849 71.1563 58.1849H69.4535Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M80.3185 56.9542C80.3185 57.7748 79.5616 58.185 78.0477 58.185H75.0158C74.7619 58.185 74.6349 58.0792 74.6349 57.8676C74.6349 57.6561 74.7619 57.5503 75.0158 57.5503H78.0477C79.0185 57.5503 79.5039 57.3361 79.5039 56.9077V56.3582C79.5039 55.9401 79.0439 55.7311 78.1223 55.7311H76.7111C75.3519 55.7311 74.6723 55.3492 74.6723 54.5854V54.0204C74.6723 53.2256 75.3693 52.8282 76.7633 52.8282H77.6C77.8485 52.8282 77.9731 52.934 77.9731 53.1456C77.9731 53.3572 77.8485 53.463 77.6 53.463H76.7709C75.9095 53.463 75.4788 53.6591 75.4788 54.0513V54.5313C75.4788 54.9131 75.8871 55.1041 76.7037 55.1041H78.0631C79.5662 55.1041 80.3185 55.5117 80.3185 56.3272V56.9542Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M89.8869 56.9542C89.8869 57.7748 89.13 58.185 87.6169 58.185H84.5846C84.3308 58.185 84.2039 58.0792 84.2039 57.8676C84.2039 57.6561 84.3308 57.5503 84.5846 57.5503H87.6169C88.5877 57.5503 89.0731 57.3361 89.0731 56.9077V56.3582C89.0731 55.9401 88.6123 55.7311 87.6916 55.7311H86.28C84.9208 55.7311 84.2408 55.3492 84.2408 54.5854V54.0204C84.2408 53.2256 84.9377 52.8282 86.3323 52.8282H87.1685C87.4177 52.8282 87.5423 52.934 87.5423 53.1456C87.5423 53.3572 87.4177 53.463 87.1685 53.463H86.3392C85.4785 53.463 85.0477 53.6591 85.0477 54.0513V54.5313C85.0477 54.9131 85.4562 55.1041 86.2723 55.1041H87.6316C89.1354 55.1041 89.8869 55.5117 89.8869 56.3272V56.9542Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M91.52 53.463H89.4815C89.2323 53.463 89.1077 53.3572 89.1077 53.1456C89.1077 52.934 89.2323 52.8282 89.4815 52.8282H94.3577C94.6115 52.8282 94.7392 52.9314 94.7392 53.1378C94.7392 53.3546 94.6115 53.463 94.3577 53.463H92.3269V57.8986C92.3269 58.1153 92.1946 58.2237 91.9307 58.2237C91.6569 58.2237 91.52 58.1153 91.52 57.8986V53.463Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M98.0354 58.1849C96.5769 58.1849 95.8477 57.6792 95.8477 56.6677V53.1146C95.8477 52.8978 95.9823 52.7894 96.2507 52.7894C96.52 52.7894 96.6538 52.8978 96.6538 53.1146V56.6445C96.6538 57.2483 97.1269 57.5502 98.073 57.5502H99.7007C100.642 57.5502 101.112 57.2483 101.112 56.6445V53.1146C101.112 52.8978 101.249 52.7894 101.523 52.7894C101.792 52.7894 101.927 52.8978 101.927 53.1146V56.6677C101.927 57.6792 101.197 58.1849 99.7384 58.1849H98.0354Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M108.766 54.3455C108.766 53.7571 108.271 53.463 107.28 53.463H104.262C104.223 53.463 104.203 53.4785 104.203 53.5095V57.5038C104.203 57.5347 104.223 57.5503 104.262 57.5503H107.272C108.268 57.5503 108.766 57.2561 108.766 56.6678V54.3455ZM103.755 58.185C103.516 58.185 103.396 58.0947 103.396 57.914V53.0992C103.396 52.9185 103.511 52.8282 103.74 52.8282H107.25C108.798 52.8282 109.572 53.3263 109.572 54.3223V56.691C109.572 57.687 108.798 58.185 107.25 58.185H103.755Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M111.011 53.1146C111.011 52.8978 111.15 52.7894 111.428 52.7894C111.688 52.7894 111.817 52.8978 111.817 53.1146V57.8985C111.817 58.1153 111.68 58.2236 111.406 58.2236C111.142 58.2236 111.011 58.1153 111.011 57.8985V53.1146Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M118.424 54.3223C118.424 53.7494 117.971 53.463 117.065 53.463H115.429C114.523 53.463 114.07 53.7494 114.07 54.3223V56.6755C114.07 57.2587 114.528 57.5503 115.445 57.5503H117.05C117.966 57.5503 118.424 57.2587 118.424 56.6755V54.3223ZM115.451 58.185C113.993 58.185 113.264 57.6896 113.264 56.6987V54.3067C113.264 53.3211 113.981 52.8282 115.415 52.8282H117.08C118.514 52.8282 119.231 53.3211 119.231 54.3067V56.6987C119.231 57.6896 118.501 58.185 117.042 58.185H115.451Z"
        fill="black"
        class="black-logo-fill"
      />
      <path
        d="M33.1379 58.6309C35.0879 58.6309 36.6687 57.0356 36.6687 55.0676C36.6687 53.0996 35.0879 51.5043 33.1379 51.5043C31.1879 51.5043 29.6071 53.0996 29.6071 55.0676C29.6071 57.0356 31.1879 58.6309 33.1379 58.6309Z"
        class="path-fill-color"
        fill="#F6F930"
      />
    </g>
    <defs>
      <clipPath id="clip0_389_437">
        <rect width="120" height="59" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
