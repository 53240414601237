<script setup>
import Paragraph1 from "../../text/Paragraph1.vue";
import Plus from "../../svg/Plus.vue";
import { ref, computed } from "vue";
import ModalForm from "../../forms/ModalForm.vue";
import FormWriteToUs from "../../forms/FormWriteToUs.vue";

const PackMyProject = ref(false);

// Пропсы
const props = defineProps({
ImageProject: String,
NameProject: String,
LinkWebsite: String,
LinkProject: String,
FormModal: String,
ClassModalForm: String,
});

// Вычисляемое свойство для определения значения href
const computedHref = computed(() => {
return props.LinkProject || props.LinkWebsite;
});
</script>

<template>
<a
    :class="FormModal"
    :href="computedHref"
    target="_blank"
    class="mb-8 basis-full px-2 md:mb-20 md:basis-1/3 lg:basis-1/4"
>
    <div
     @click="PackMyProject = FormModal ? true : false"
     class="group relative overflow-hidden rounded-4xl duration-300 lg:hover:bg-black"
    >
     <div
        class="absolute top-0 h-full w-full bg-[url('/img/portfolio/hover.svg')] bg-no-repeat opacity-0 duration-300 lg:group-hover:opacity-100"
     ></div>

     <img
        :src="props.ImageProject"
        :alt="props.NameProject"
        class="h-auto w-full duration-300 lg:group-hover:scale-125 lg:group-hover:opacity-20"
     />

     <div
        class="absolute bottom-2 opacity-0 duration-300 lg:group-hover:opacity-100"
     >
        <a
         v-if="props.LinkWebsite"
         :href="props.LinkWebsite"
         target="_blank"
         class="ps-5 text-yellow-500 hover:underline"
        >
         <Paragraph1>
            {{ $t("portfolio.website") }}
         </Paragraph1>
        </a>
     </div>

     <div
        class="absolute bottom-2 right-0 opacity-0 duration-300 lg:group-hover:opacity-100"
     >
        <router-link
         v-if="props.LinkProject"
         :to="props.LinkProject"
         class="right-0 pe-5 text-yellow-500 hover:underline"
         target="_blank"
        >
         <Paragraph1>
            {{ $t("portfolio.project") }}
         </Paragraph1>
        </router-link>
     </div>

     <div
        v-if="props.FormModal"
        class="absolute left-6 top-6 w-1/2 sm:w-2/3 2xl:w-1/2"
     >
        <Paragraph1 class="duration-300 md:group-hover:text-yellow-500">
         {{ $t("portfolio.yellow_block") }}
        </Paragraph1>
     </div>
     <div v-if="props.FormModal" class="absolute bottom-6 right-6">
        <Plus />
     </div>
    </div>
    <div class="mt-2 flex flex-row lg:hidden">
     <div class="basis-1/2 ps-4" v-if="props.LinkWebsite">
        <a :href="props.LinkWebsite" target="_blank" class="hover:underline">
         <Paragraph1>
            {{ $t("portfolio.website") }}
         </Paragraph1>
        </a>
     </div>
     <div class="basis-1/2 pe-4 text-end" v-if="props.LinkProject">
        <router-link :to="props.LinkProject" class="hover:underline">
         <Paragraph1>
            {{ $t("portfolio.project") }}
         </Paragraph1>
        </router-link>
     </div>
    </div>
</a>

<ModalForm v-if="PackMyProject" @close="PackMyProject = false">
    <Paragraph1>
     {{ $t("header.pack_my_project") }}
    </Paragraph1>
</ModalForm>
</template>