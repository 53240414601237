<script setup>
import { defineAsyncComponent, ref } from "vue";
import { Splide } from "@splidejs/vue-splide";
import "@splidejs/vue-splide/css";

import SliderBlock from "../components/shared/SliderBlock.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import HrNumber from "../components/shared/HrNumber.vue";
import YellowBlackButton from "../components/buttons/Yellow-BlackButton.vue";
import BorderBlackBlackButton from "../components/buttons/BorderBlack-BlackButton.vue";

import Headline1 from "../components/text/Headline1.vue";
import Headline2 from "../components/text/Headline2.vue";
import Paragraph1 from "../components/text/Paragraph1.vue";
import Paragraph3 from "../components/text/Paragraph3.vue";

import ServiceItem from "../components/page/home/ServiceItem.vue";
import AboutUsItem from "../components/page/home/AboutUsItem.vue";

import FormWriteToUs from "../components/forms/FormWriteToUs.vue";
import ModalForm from "../components/forms/ModalForm.vue";

const TopBlock = defineAsyncComponent(
  () => import("../components/page/home/TopBlock.vue"),
);

const showModal = ref(false);
</script>

<template>
  <!-- block - 1 - Hero -->
  <BlockSection class="mt-8 md:mt-12">
    <Container>
      <div class="mx-2 flex flex-row">
        <div class="basis-full px-2">
          <div
            class="relative h-[560px] w-full rounded-4xl sm:h-[600px] md:h-[789px] lg:h-[600px] xl:h-[720px] 2xl:h-[745px]"
          >
            <TopBlock />
          </div>
        </div>
      </div>
    </Container>
  </BlockSection>

  <!-- block - 2 - About Us -->
  <BlockSection>
    <Container>
      <div class="mx-2 flex flex-row flex-wrap justify-between">
        <div class="basis-full px-2">
          <HrNumber title="01" />
        </div>
        <div class="basis-12/12 px-2 lg:basis-4/12">
          <h2 class="mb-6 md:mb-8 lg:mb-0">
            <Headline2>
              {{ $t("header.about_us") }}
            </Headline2>
          </h2>
        </div>
        <div class="basis-12/12 px-2 lg:basis-7/12">
          <div class="mb-11">
            <AboutUsItem
              v-for="(about, index) in aboutUsItems"
              :key="index"
              :title="about.title"
              :description="about.description"
            />
          </div>
          <router-link to="/about-us" exact>
            <YellowBlackButton>
              {{ $t("buttons.get_to_know_the_team") }}
            </YellowBlackButton>
          </router-link>
        </div>
      </div>
    </Container>
  </BlockSection>

  <!-- block - 3 - Services -->
  <BlockSection>
    <Container>
      <div class="mx-2 flex flex-row flex-wrap justify-between">
        <div class="basis-full px-2">
          <HrNumber title="02" />
        </div>
        <div class="basis-full px-2 lg:basis-4/12">
          <h2 class="mb-8 md:mb-12">
            <Headline2>
              {{ $t("header.services") }}
            </Headline2>
          </h2>
          <div class="hidden lg:block">
            <p>
              <Paragraph3>
                {{ $t("home.fill_in_for") }}
              </Paragraph3>
            </p>
            <FormWriteToUs />
          </div>
        </div>
        <div class="basis-full px-2 lg:basis-7/12">
          <ServiceItem
            v-for="(service, index) in services"
            :key="index"
            :icon-src="service.iconSrc"
            :icon-alt="service.iconAlt"
            :title="service.title"
            :description="service.description"
          />
        </div>
        <div class="basis-full sm:mt-6 md:mt-4 lg:hidden">
          <ModalForm v-if="showModal" @close="showModal = false">
            <h3>
              <Paragraph1 class="text-yellow-500">
                {{ $t("buttons.apply_now") }}
              </Paragraph1>
            </h3>
            <FormWriteToUs />
          </ModalForm>
          <YellowBlackButton @click="showModal = true">
            {{ $t("buttons.apply_now") }}
          </YellowBlackButton>
        </div>
      </div>
    </Container>
  </BlockSection>

  <!-- block - 4 - Portfolio -->
  <BlockSection>
    <Container>
      <div class="mx-2 flex flex-row flex-wrap">
        <div class="basis-full px-2">
          <HrNumber title="03" />
        </div>
        <div class="mb-6 flex basis-full flex-row items-end md:mb-8">
          <div class="basis-1/2 px-2">
            <h2>
              <Headline2>
                {{ $t("header.portfolio") }}
              </Headline2>
            </h2>
          </div>
          <div class="hidden basis-1/2 px-2 text-end sm:block">
            <router-link to="/portfolio" exact>
              <BorderBlackBlackButton>
                {{ $t("buttons.view_all") }}
              </BorderBlackBlackButton>
            </router-link>
          </div>
        </div>
        <div class="basis-full px-2">
          <Splide
            :options="splideOptions"
            aria-label="My Favorite Images"
            class="responsive-splide"
          >
            <SliderBlock
              v-for="(project, index) in portfolio_projects"
              :key="index"
              :title="project.title"
              :description="project.description"
              :NameButton="$t('buttons.view_the_case')"
              :link="project.link"
              :icon-src="project.iconSrc"
              :icon-alt="project.iconAlt"
              :orderBlock1="project.orderBlock1"
              :orderBlock2="project.orderBlock2"
              :orderBlockAll="project.orderBlockAll"
              :class="{
                'pb-6 md:pb-8 lg:pb-0': index !== portfolio_projects.length - 1,
              }"
            />
          </Splide>
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script>
export default {
  components: {
    BlockSection,
    HrNumber,
    Headline1,
    Headline2,
    Paragraph1,
    Paragraph3,
    YellowBlackButton,
    ServiceItem,
    AboutUsItem,
    SliderBlock,
    ModalForm,
  },
  data() {
    return {
      splideOptions: {
        paginationDirection: "rtl",
        gap: 20,
        rewind: true,
        destroy: window.innerWidth < 1024,
      },
    };
  },
  computed: {
    aboutUsItems() {
      return [
        {
          title: this.$t("home.abouts.1.title"),
          description: this.$t("home.abouts.1.description"),
        },
        {
          title: this.$t("home.abouts.2.title"),
          description: this.$t("home.abouts.2.description"),
        },
        {
          title: this.$t("home.abouts.3.title"),
          description: this.$t("home.abouts.3.description"),
        },
        {
          title: this.$t("home.abouts.4.title"),
          description: this.$t("home.abouts.4.description"),
        },
      ];
    },
    services() {
      return [
        {
          iconSrc: "/img/home/icon_web_development.svg",
          iconAlt: this.$t("home.services.1.iconAlt"),
          title: this.$t("home.services.1.title"),
          description: this.$t("home.services.1.description"),
        },
        {
          iconSrc: "/img/home/icon_web_design.svg",
          iconAlt: this.$t("home.services.2.iconAlt"),
          title: this.$t("home.services.2.title"),
          description: this.$t("home.services.2.description"),
        },
        {
          iconSrc: "/img/home/icon_brand_strategy.svg",
          iconAlt: this.$t("home.services.3.iconAlt"),
          title: this.$t("home.services.3.title"),
          description: this.$t("home.services.3.description"),
        },
        {
          iconSrc: "/img/home/icon_content_strategy.svg",
          iconAlt: this.$t("home.services.4.iconAlt"),
          title: this.$t("home.services.4.title"),
          description: this.$t("home.services.4.description"),
        },
        {
          iconSrc: "/img/home/icon_business_process.svg",
          iconAlt: this.$t("home.services.5.iconAlt"),
          title: this.$t("home.services.5.title"),
          description: this.$t("home.services.5.description"),
        },
      ];
    },
    portfolio_projects() {
      return [
        {
          title: this.$t("home.portfolio_projects.1.title"),
          description: this.$t("home.portfolio_projects.1.description"),
          link: "/portfolio/vera-fund",
          iconSrc: "/img/home/home_vera_fund.webp",
          iconAlt: this.$t("home.portfolio_projects.1.iconAlt"),
          orderBlock1:
            "lg:order-2 justify-end order-2 rounded-tr-none rounded-br-4xl rounded-bl-4xl lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
          orderBlock2:
            "lg:order-1 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-bl-4xl lg:rounded-tr-none",
          orderBlockAll: "items-end justify-end",
        },
        {
          title: this.$t("home.portfolio_projects.3.title"),
          description: this.$t("home.portfolio_projects.3.description"),
          link: "/portfolio/aventura-quattro",
          iconSrc: "/img/home/home_aventura.webp",
          iconAlt: this.$t("home.portfolio_projects.3.iconAlt"),
          orderBlock1:
            "lg:order-1 order-2 rounded-tr-none justify-center lg:rounded-tl-4xl rounded-br-4xl rounded-bl-4xl lg:rounded-bl-4xl lg:rounded-tr-none",
          orderBlock2:
            "lg:order-2 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-tl-none lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
          orderBlockAll: "items-center justify-center",
        },
        {
          title: this.$t("home.portfolio_projects.2.title"),
          description: this.$t("home.portfolio_projects.2.description"),
          link: "/portfolio/coral-ridge",
          iconSrc: "/img/home/home_coral_ridge.webp",
          iconAlt: this.$t("home.portfolio_projects.2.iconAlt"),
          orderBlock1:
            "lg:order-2 order-2 justify-end rounded-tr-none rounded-br-4xl rounded-bl-4xl lg:rounded-tr-4xl lg:rounded-br-4xl lg:rounded-bl-none",
          orderBlock2:
            "lg:order-1 order-1 rounded-tl-4xl rounded-tr-4xl lg:rounded-bl-4xl lg:rounded-tr-none",
          orderBlockAll: "items-end justify-end",
        },
      ];
    },
  },
};
</script>
