<script setup>
import Paragraph1 from "../text/Paragraph1.vue";
</script>

<template>
  <button
    class="whitespace-nowrap rounded-5xl px-8 py-4 transition delay-0 ease-out"
  >
    <Paragraph1>
      <slot />
    </Paragraph1>
  </button>
</template>

<script>
export default {
  components: {
    Paragraph1,
  },
};
</script>
