<script setup>
import { useI18n } from "vue-i18n";
import Paragraph1 from "../text/Paragraph1.vue";

// Используем `useI18n` в самом начале `setup`
const { availableLocales, locale } = useI18n();

// Функция для изменения локали
const changeLocale = (newLocale) => {
  locale.value = newLocale; // Устанавливаем новую локаль
};
</script>

<template>
  <button class="flex items-center rounded-4xl border border-black p-3">
    <a
      v-for="(availableLocale, index) in availableLocales"
      :key="`locale-${availableLocale}`"
      @click="changeLocale(availableLocale)"
      :class="{ 'text-gray-300': locale === availableLocale }"
      class="cursor-pointer hover:text-gray-300"
    >
      <Paragraph1>
        {{ availableLocale }}
      </Paragraph1>
      <span
        v-if="index !== availableLocales.length - 1"
        class="mx-1 text-black"
      >/</span>
    </a>
  </button>
</template>
