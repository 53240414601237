import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";

import { createI18n } from "vue-i18n";
import VueCookies from "vue-cookies";

import localesEn from "./locales/EN.js";
import localesRu from "./locales/RU.js";

import "./style.css";

import App from "./App.vue";

import Home from "./views/Home.vue";
import AboutUs from "./views/AboutUs.vue";
import Services from "./views/Services.vue";
import MoreAboutServices from "./views/MoreAboutServices.vue";
import Portfolio from "./views/Portfolio.vue";
import VeraFund from "./views/portfolio/VeraFund.vue";
import AventuraQuattro from "./views/portfolio/AventuraQuattro.vue";
import CoralRidge from "./views/portfolio/CoralRidge.vue";
import TermsAndConditions from "./views/legal/TermsAndConditions.vue";
import PrivacyPolicy from "./views/legal/PrivacyPolicy.vue";
import Glossary from "./views/Glossary.vue";
import Contact from "./views/Contact.vue";
import NotFound from "./views/NotFound.vue";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: Home, meta: { hr_title_footer: "04" } },
    { path: "/about-us", component: AboutUs },
    { path: "/services", component: Services },
    { path: "/more-about-services", component: MoreAboutServices },
    { path: "/portfolio", component: Portfolio },
    { path: "/portfolio/vera-fund", component: VeraFund },
    { path: "/portfolio/aventura-quattro", component: AventuraQuattro },
    { path: "/portfolio/coral-ridge", component: CoralRidge },
    { path: "/legal/terms-and-conditions", component: TermsAndConditions },
    { path: "/legal/privacy-policy", component: PrivacyPolicy },
    { path: "/glossary", component: Glossary },
    { path: "/contact", component: Contact, meta: { hr_none_footer: "false" } },
    { path: "/404", component: NotFound },
    { path: "/:catchAll(.*)", redirect: "/404" },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

const messages = {
  En: localesEn,
  Ru: localesRu,
};

const i18n = createI18n({
  locale: "En",
  messages: messages,
  legacy: false,
});

export default i18n;

const app = createApp(App);

app.use(router);
app.use(i18n);
app.use(VueCookies);
app.mount("#app");
