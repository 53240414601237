<script setup>
import Headline3 from "../text/Headline3.vue";
</script>

<template>
  <div class="mb-6 md:mb-8">
    <Headline3 class="text-gray-300">
      {{ title }}
    </Headline3>
    <hr class="h-px border-gray-300" />
  </div>
</template>

<script>
export default {
  components: {
    Headline3,
  },
  props: {
    title: String,
  },
};
</script>
