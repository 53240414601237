<script setup>
import Header from "./components/layout/Header.vue";
import Footer from "./components/layout/Footer.vue";
import Cookies from "./components/Cookies.vue";
import BitrixLoader from './components/BitrixLoader.vue';
</script>

<template>
  <Header />
  <main>
    <router-view />
  </main>
  <Footer />
  <Cookies />
  <BitrixLoader />
</template>

<script>
export default {
  components: {
    Header,
    Footer,
    Cookies,
    BitrixLoader,
  },
};
</script>
