<script setup>
import ModalButton from "../buttons/ModalButton.vue";
</script>

<template>
  <ModalButton
    class="bg-yellow-500 text-black hover:bg-black hover:text-yellow-500"
  >
    <slot />
  </ModalButton>
</template>

<script>
export default {
  components: {
    ModalButton,
  },
};
</script>
