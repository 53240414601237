<script setup>
import FormWriteToUs from "../forms/FormWriteToUs.vue";
import BlockSection from "../shared/BlockSection.vue";
import Container from "../shared/Container.vue";
import HrNumber from "../shared/HrNumber.vue";
import Headline2 from "../text/Headline2.vue";
import Headline4 from "../text/Headline4.vue";
import Paragraph2_1 from "../text/Paragraph2_1.vue";
import Paragraph3 from "../text/Paragraph3.vue";

import PhoneFooter from "../svg/PhoneFooter.vue";
import MailFooter from "../svg/MailFooter.vue";
import WhatsAppFooter from "../svg/WhatsAppFooter.vue";
import TelegramFooter from "../svg/TelegramFooter.vue";

import SelectLanguage from "../../components/shared/SelectLanguage.vue";
</script>

<template>
  <footer>
    <BlockSection>
      <Container>
        <div class="mx-2 flex flex-row flex-wrap">
          <div class="basis-full px-2">
            <HrNumber v-if="!hr_none_footer" :title="hr_title_footer" />
          </div>
          <div class="basis-full px-2 text-start">
            <h2 class="mb-8 md:mb-12">
              <Headline2>
                {{ $t("footer.contact") }}
              </Headline2>
            </h2>
          </div>
        </div>

        <div class="mx-2 flex flex-row flex-wrap justify-between">
          <div
            class="mb-12 basis-full px-2 sm:basis-7/12 md:mb-0 md:basis-6/12 lg:basis-5/12 2xl:basis-4/12"
          >
            <Paragraph3>
              {{ $t("home.fill_in_for") }}
            </Paragraph3>
            <FormWriteToUs />
          </div>

          <div class="basis-full md:basis-5/12 lg:basis-6/12">
            <div class="mx-4 flex flex-row flex-wrap">
              <div class="basis-full lg:basis-1/2">
                <div class="mb-8">
                  <a
                    class="group flex transition duration-300 hover:text-gray-300 hover:underline"
                    href="tel:+1 (305) 842-9414"
                  >
                    <PhoneFooter class="me-1" />
                    <Headline4>+1 (305) 842-9414</Headline4>
                  </a>
                </div>
                <div class="mb-8 2xl:mb-0">
                  <a href="https://t.me/PolyusStudio" target="_blank">
                    <TelegramFooter class="group float-start me-5" />
                  </a>
                  <a href="https://wa.me/13058429414" target="_blank">
                    <WhatsAppFooter class="group flex" />
                  </a>
                </div>
              </div>
              <div class="basis-full lg:basis-1/2">
                <a
                  class="group flex duration-300 hover:text-gray-300 hover:underline"
                  href="mailto:info@polyusstudio.com"
                >
                  <MailFooter class="me-1" />
                  <Headline4>info@polyusstudio.com</Headline4>
                </a>
                <div class="mt-8">
                  <div class="rounded-4xl">
                    <SelectLanguage class="py-[15px]" />
                  </div>
                </div>
              </div>
              <div class="my-10 basis-full">
                <router-link to="/">
                  <img
                    src="/img/icons/logo.svg"
                    alt=""
                    class="h-[95px] w-[196px]"
                  />
                </router-link>
              </div>
            </div>

            <div class="mx-4 flex flex-row flex-wrap">
              <div class="basis-full 2xl:basis-1/2">
                <div class="mb-4">
                  <router-link to="/contact" exact class="hover:underline">
                    <Paragraph3>
                      {{ $t("footer.contact") }}
                    </Paragraph3>
                  </router-link>
                </div>
                <!-- <div class="mb-4">
                  <router-link to="/glossary" exact class="hover:underline">
                    <Paragraph3>
                      {{ $t("footer.glossary") }}
                    </Paragraph3>
                  </router-link>
                </div> -->
                <div class="mb-8 2xl:mb-0">
                  <router-link
                    to="/more-about-services"
                    exact
                    class="hover:underline"
                  >
                    <Paragraph3>
                      {{ $t("footer.more_about_services") }}
                    </Paragraph3>
                  </router-link>
                </div>
              </div>
              <div class="basis-full 2xl:basis-1/2">
                <div class="mb-4">
                  <router-link
                    to="/legal/terms-and-conditions"
                    exact
                    class="hover:underline"
                  >
                    <Paragraph2_1>
                      {{ $t("footer.terms_conditions") }}
                    </Paragraph2_1>
                  </router-link>
                </div>
                <div class="mb-4">
                  <router-link
                    to="/legal/privacy-policy"
                    exact
                    class="hover:underline"
                  >
                    <Paragraph2_1>
                      {{ $t("footer.privacy_policy") }}
                    </Paragraph2_1>
                  </router-link>
                </div>
                <div>
                  <Paragraph2_1>
                    {{ $t("footer.llc") }}
                  </Paragraph2_1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </BlockSection>
  </footer>
</template>

<script>
export default {
  components: {
    FormWriteToUs,
    BlockSection,
    Container,
    HrNumber,
    Headline2,
    Headline4,
    Paragraph2_1,
    Paragraph3,
    SelectLanguage,
  },
  computed: {
    hr_title_footer() {
      return this.$route.meta.hr_title_footer;
    },
    hr_none_footer() {
      return this.$route.meta.hr_none_footer;
    },
  },
};
</script>
