<script setup>
import { ref } from "vue";
import ModalForm from "../forms/ModalForm.vue";
import Container from "../shared/Container.vue";
import YellowBlackButton from "../buttons/Yellow-BlackButton.vue";
import Headline2 from "../text/Headline2.vue";
import Paragraph1 from "../text/Paragraph1.vue";
import Logo from "../svg//Logo.vue";
import WhatsappHeader from "../svg/WhatsappHeader.vue";

const PackMyProject = ref(false);
</script>

<template>
  <header class="duration-700" :class="{ 'mb-[295px]': isMenuOpen }">
    <Container class="mt-8 md:mt-12">
      <div
        ref="firstBlock"
        class="relative mx-4 flex flex-row items-center rounded-4xl bg-black md:mx-2 md:bg-inherit"
      >
        <!-- Logo -->
        <div
          class="basis-6/12 px-2 sm:order-2 sm:basis-4/12 md:order-none md:basis-2/12 xl:basis-1/12 2xl:basis-2/12"
        >
          <router-link to="/" aria-label="Home page">
            <Logo
              class="me-auto duration-300 sm:mx-auto 2xl:me-auto 2xl:ms-0"
            />
          </router-link>
        </div>

        <!-- Menu -->
        <div
          class="hidden px-2 text-white md:flex md:basis-9/12 2xl:basis-7/12"
        >
          <div
            class="flex h-[59px] w-full items-center justify-between rounded-5xl bg-black"
          >
            <div class="mx-auto">
              <router-link to="/" exact class="text-white hover:underline">
                <Paragraph1>
                  {{ $t("header.home") }}
                </Paragraph1>
              </router-link>
            </div>
            <div class="mx-auto">
              <router-link
                to="/about-us"
                exact
                class="text-white hover:underline"
              >
                <Paragraph1 class="whitespace-nowrap">
                  {{ $t("header.about_us") }}
                </Paragraph1>
              </router-link>
            </div>
            <div class="mx-auto">
              <router-link
                to="/services"
                exact
                class="text-white hover:underline"
              >
                <Paragraph1>
                  {{ $t("header.services") }}
                </Paragraph1>
              </router-link>
            </div>
            <div class="mx-auto">
              <router-link
                to="/portfolio"
                exact
                class="text-white hover:underline"
              >
                <Paragraph1>
                  {{ $t("header.portfolio") }}
                </Paragraph1>
              </router-link>
            </div>
          </div>
        </div>

        <!-- Button PackMyProject -->
        <div class="hidden px-2 lg:block lg:basis-2/12 xl:basis-3/12">
          <YellowBlackButton class="w-full" @click="PackMyProject = true">
            <Paragraph1 class="whitespace-nowrap">
              <span class="hidden xl:block">
                {{ $t("header.pack_my_project") }}
              </span>
              <span class="block xl:hidden">
                {{ $t("header.inquire") }}
              </span>
            </Paragraph1>
          </YellowBlackButton>
        </div>

        <ModalForm v-if="PackMyProject" @close="PackMyProject = false">
          <Paragraph1>
            {{ $t("header.pack_my_project") }}
          </Paragraph1>
        </ModalForm>

        <!-- WhatsApp -->
        <div
          class="hidden rounded-4xl px-2 sm:order-1 sm:block sm:basis-4/12 md:-order-none md:basis-1/12"
        >
          <a href="https://wa.me/13058429414" target="_blank">
            <button
              class="whatsapp-fill-button rounded-4xl bg-gray-300 p-2 duration-300 hover:bg-black md:h-full md:p-3 lg:w-full"
            >
              <WhatsappHeader
                class="mx-auto h-7 w-auto px-1 duration-300 md:h-8"
              />
            </button>
          </a>
        </div>

        <!-- Burger -->
        <div
          class="order-3 block basis-6/12 rounded-tl-4xl text-end sm:basis-4/12 md:hidden"
        >
          <button
            aria-label="Menu"
            class="group m-1 rounded-4xl border border-white px-[15px] py-[14px]"
            @click="toggleMenu"
          >
            <span
              class="block h-[1px] w-5 bg-white duration-100 group-active:bg-yellow-500"
            ></span>
            <span class="flex h-[6px] w-5 bg-black"></span>
            <span
              class="block h-[1px] w-5 bg-white duration-100 group-active:bg-yellow-500"
            ></span>
            <span class="flex h-[6px] w-5 bg-black"></span>
            <span
              class="block h-[1px] w-5 bg-white duration-100 group-active:bg-yellow-500"
            ></span>
          </button>
        </div>
        <!-- Mini Menu -->
        <div
          :style="{ width: secondBlockWidth + 'px' }"
          class="absolute top-[-33px] -z-[1] h-16 bg-white"
        ></div>
        <div
          :class="{
            'top-[15px]': isMenuOpen,
          }"
          :style="{ width: secondBlockWidth + 'px' }"
          class="absolute top-[-530px] -z-[2] h-14 bg-black duration-700"
        ></div>
        <div
          :class="{
            'top-[53px]': isMenuOpen,
            'z-20': isMenuOpenDelayed2,
            '-z-[2]': !isMenuOpenDelayed,
          }"
          :style="{ width: secondBlockWidth + 'px' }"
          class="absolute top-[-500px] order-5 flex justify-center rounded-b-4xl bg-black text-center duration-700 md:hidden"
        >
          <div class="">
            <ul class="text-center text-white">
              <li class="mb-8 mt-9">
                <router-link
                  to="/"
                  exact
                  class="text-white hover:underline"
                  @click="toggleMenu"
                >
                  <Paragraph1 class="mb-8">
                    {{ $t("header.home") }}
                  </Paragraph1>
                </router-link>
              </li>
              <li class="mb-8">
                <router-link
                  to="/about-us"
                  exact
                  class="text-white hover:underline"
                  @click="toggleMenu"
                >
                  <Paragraph1 class="mb-8 whitespace-nowrap">
                    {{ $t("header.about_us") }}
                  </Paragraph1>
                </router-link>
              </li>
              <li class="mb-8">
                <router-link
                  to="/services"
                  exact
                  class="text-white hover:underline"
                  @click="toggleMenu"
                >
                  <Paragraph1 class="mb-8">
                    {{ $t("header.services") }}
                  </Paragraph1>
                </router-link>
              </li>
              <li class="mb-8">
                <router-link
                  to="/portfolio"
                  exact
                  class="text-white hover:underline"
                  @click="toggleMenu"
                >
                  <Paragraph1 class="pb-14">
                    {{ $t("header.portfolio") }}
                  </Paragraph1>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Container>
  </header>
</template>

<script>
export default {
  components: {
    Logo,
    Container,
    Headline2,
    Paragraph1,
  },
  data() {
    return {
      isMenuOpen: false,
      isMenuOpenDelayed: false,
      isMenuOpenDelayed2: false,
      secondBlockWidth: 0,
    };
  },
  mounted() {
    this.setSecondBlockWidth();
    window.addEventListener("resize", this.setSecondBlockWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setSecondBlockWidth);
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      if (this.isMenuOpen) {
        setTimeout(() => {
          isMenuOpenDelayed2: true, (this.isMenuOpenDelayed = true);
        }, 600);
      } else {
        this.isMenuOpenDelayed = false;
      }
    },
    setSecondBlockWidth() {
      this.secondBlockWidth = this.$refs.firstBlock.clientWidth;
    },
  },
};
</script>
