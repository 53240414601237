<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    class="group-hover:fill-gray-300 duration-300"
      d="M29.8709 4.35447C29.3415 4.22468 28.7398 4.24512 28.0025 4.44491C23.7308 5.60283 6.75007 11.1211 5.35269 11.7395C3.82975 12.4132 2.95096 13.6003 2.9374 15.1578C2.91932 17.2403 4.59786 18.8751 6.58323 18.8051C6.64711 18.8037 9.10367 18.8372 10.5025 18.8518C10.5025 18.8518 11.7511 25.0949 11.7813 25.1693C12.1458 26.0647 13.0006 26.3316 13.7381 26.0093L17.8853 24.1849C18.3548 24.6472 21.5112 27.6966 21.8501 27.9693C22.8601 28.7801 23.7329 29.0353 24.8124 29.063C26.5503 29.1082 28.0684 28.086 28.8683 26.4191C29.3704 25.3735 31.7106 10.7303 31.9218 9.27782C32.257 6.9722 32.1812 6.06657 31.2381 5.12886C30.8591 4.75261 30.4004 4.48281 29.8709 4.35447ZM29.1873 7.18218C29.1873 7.18218 29.2581 7.4403 29.0506 8.86655C28.3082 13.9736 26.5487 24.4226 26.1796 25.2787C25.9274 25.8649 25.4221 26.1609 24.8579 26.1449C24.3613 26.1318 24.1141 26.0443 23.673 25.6899C23.4461 25.5076 22.4251 24.5378 20.9843 23.1364C20.9662 23.1189 18.1105 20.332 18.1105 20.332C17.8321 20.0549 17.5705 19.8901 17.0223 19.8843C16.7035 19.8843 16.2828 20.1264 16.2828 20.1264L14.0897 21.9828L13.328 18.2582L22.4881 14.3382C23.2281 14.0203 23.5811 13.1628 23.2629 12.4234C22.9449 11.6826 22.0888 11.3297 21.3489 11.6476L11.414 15.9337C10.8483 15.9307 6.63296 15.8826 6.49209 15.8884C6.16761 15.8986 5.85042 15.5807 5.85407 15.1578C5.85698 14.8195 6.00617 14.6183 6.53759 14.3835C7.7693 13.838 24.5449 8.39696 28.6854 7.2755C29.076 7.16905 29.1873 7.18218 29.1873 7.18218Z"
      fill="black"
    />
  </svg>
</template>
