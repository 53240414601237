<script setup>
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import Headline1 from "../components/text/Headline1.vue";
import Headline3 from "../components/text/Headline3.vue";
import Paragraph1 from "../components/text/Paragraph1.vue";
import Paragraph2_1 from "../components/text/Paragraph2_1.vue";
import Paragraph3 from "../components/text/Paragraph3.vue";
</script>

<template>
    <BlockSectionTop/>
  <BlockSection>
    <Container>
      <div class="flex flex-row justify-end mx-4">
        <div class="2xl:basis-10/12 xl:basis-11/12 lg:basis-full text-start">
          <h1 class="md:mb-20 mb-16">
            <Headline1>
              {{ $t("header.services") }}
            </Headline1>
          </h1>

          <div class="md:mb-12 mb-8">
            <div class="md:mb-11 mb-10">
              <h2 class="md:mb-6 mb-5">
                <Headline3>
                  {{ $t("more_about_services.block_1.h2") }}
                </Headline3>
              </h2>
              <p>
                <Paragraph2_1>
                  {{ $t("more_about_services.block_1.p") }}
                </Paragraph2_1>
              </p>
            </div>
            <div class="mb-5">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_1.h3_1") }}
                </Paragraph1>
              </h3>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_4") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_5") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_6") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_7") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_8") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_9") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_10") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_11") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_12") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_13") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_14") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_15") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_16") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_1_17") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
            <div class="md:mb-12 mb-8">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_1.h3_2") }}
                </Paragraph1>
              </h3>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_4") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_5") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_6") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_7") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_8") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_9") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_10") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_11") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_12") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_13") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_14") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_15") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_16") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_17") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_18") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_19") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_20") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_21") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_22") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_23") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_24") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_25") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_26") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_27") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_28") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_29") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_30") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_31") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_32") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_33") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_34") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_35") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_36") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_37") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_38") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_2_39") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
            <div class="md:mb-12 mb-8">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_1.h3_3") }}
                </Paragraph1>
              </h3>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_4") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_5") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_6") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_7") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_8") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_9") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_10") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_11") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_1.li_3_12") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:mb-12 mb-8">
            <div class="md:mb-11 mb-10">
              <h2 class="md:mb-6 mb-5">
                <Headline3>
                  {{ $t("more_about_services.block_2.h2") }}
                </Headline3>
              </h2>
              <p>
                <Paragraph2_1>
                  {{ $t("more_about_services.block_2.p") }}
                </Paragraph2_1>
              </p>
            </div>
            <div class="md:mb-6 mb-5">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_2.1.h3") }}
                </Paragraph1>
              </h3>
              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.h4_1") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_1.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_1.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_1.3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_1.4") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.h4_1") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_2.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_2.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_2.3") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.h4_2") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_3.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_3.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_3.3") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.h4_3") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_4.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_4.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_4.3") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.h4_5") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_5.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_5.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.1.li_5.3") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <p class="md:mt-6 mt-5">
                <Paragraph3>
                  {{ $t("more_about_services.block_2.1.p") }}
                </Paragraph3>
              </p>
            </div>

            <div class="md:mb-11 mb-10">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_2.2.h3") }}
                </Paragraph1>
              </h3>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.2.h4_1") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_1.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_1.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_1.3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_1.4") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.2.h4_2") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_2.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_2.2") }}
                  </Paragraph2_1>
                  <ul class="list-disc list-inside ps-4">
                    <li>
                      <Paragraph2_1>
                        {{ $t("more_about_services.block_2.2.li_2.li.1") }}
                      </Paragraph2_1>
                    </li>
                    <li>
                      <Paragraph2_1>
                        {{ $t("more_about_services.block_2.2.li_2.li.2") }}
                      </Paragraph2_1>
                    </li>
                  </ul>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_2.3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_2.4") }}
                  </Paragraph2_1>
                </li>
              </ul>

              <h4>
                <Paragraph3>
                  {{ $t("more_about_services.block_2.2.h4_3") }}
                </Paragraph3>
              </h4>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_3.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_2.2.li_3.2") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
          </div>

          <div>
            <div class="md:mb-11 mb-10">
              <h2 class="md:mb-6 mb-5">
                <Headline3>
                  {{ $t("more_about_services.block_3.h2") }}
                </Headline3>
              </h2>
              <p>
                <Paragraph2_1>
                  {{ $t("more_about_services.block_3.p") }}
                </Paragraph2_1>
              </p>
            </div>
            <div class="md:mb-6 mb-5">
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_3.1.h3") }}
                </Paragraph1>
              </h3>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.1.li.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.1.li.2") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.1.li.3") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.1.li.4") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.1.li.5") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
            <div>
              <h3 class="md:mb-6 mb-5">
                <Paragraph1>
                  {{ $t("more_about_services.block_3.2.h3") }}
                </Paragraph1>
              </h3>
              <ul class="list-disc list-inside">
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.2.li.1") }}
                  </Paragraph2_1>
                </li>
                <li>
                  <Paragraph2_1>
                    {{ $t("more_about_services.block_3.2.li.2") }}
                  </Paragraph2_1>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script>
export default {
  components: {
    BlockSection,
    Container,
    Headline1,
    Headline3,
    Paragraph1,
    Paragraph2_1,
    Paragraph3,
  },
  data() {
    return {};
  },
};
</script>
