<template>
  <BlockSection>
    <BlockSectionTop />

    <!-- block - 1 - Headline 1 -->
    <Container>
      <div class="mx-2 flex flex-row">
        <div class="mb-14 basis-full px-2 text-center md:mb-20 lg:text-left">
          <h1>
            <Headline1>
              {{ AboutUs.h1 }}
            </Headline1>
          </h1>
        </div>
      </div>
    </Container>

    <!-- block - 2 - Team -->
    <Container>
      <div class="mx-2 flex flex-row flex-wrap">
        <div class="mb-20 flex basis-full flex-row flex-wrap">
          <div class="mb-14 basis-full px-2 text-start lg:mb-0 lg:basis-4/12">
            <div class="overflow-hidden rounded-4xl">
              <img
                :src="AboutUs.boss.image"
                class="h-auto w-full duration-500 hover:translate-y-3 hover:scale-110"
                :alt="AboutUs.boss.name + AboutUs.boss.job"
              />
            </div>
          </div>
          <div class="flex flex-wrap text-start lg:basis-8/12">
            <div class="flex basis-full flex-row flex-wrap">
              <div class="basis-full px-2 sm:basis-1/2">
                <h2>
                  <Headline3>
                    {{ AboutUs.boss.name }}
                  </Headline3>
                </h2>
              </div>
              <div class="basis-full px-2 sm:basis-1/2 sm:text-end">
                <Paragraph2>
                  {{ AboutUs.boss.job }}
                </Paragraph2>
              </div>
            </div>
            <div class="basis-full px-2">
              <hr class="mb-4" />
              <p>
                <Paragraph2>
                  {{ AboutUs.boss.text }}
                </Paragraph2>
              </p>
            </div>
          </div>
        </div>
        <div class="flex basis-full flex-row flex-wrap justify-center">
          <AboutUsCard
            v-for="(employee, index) in AboutUs.team"
            :key="index"
            :name="employee.name"
            :image="employee.image"
            :job="employee.job"
            class="h-auto w-full"
            :class="{
              'mb-14 md:mb-20': index !== AboutUs.team.length - 1,
            }"
          />
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script setup>
import { reactive, watch } from "vue";
import { useI18n } from "vue-i18n";

import Headline1 from "../components/text/Headline1.vue";
import Headline3 from "../components/text/Headline3.vue";
import Paragraph2 from "../components/text/Paragraph2.vue";
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import AboutUsCard from "../components/page/about/AboutUsCard.vue";

const { tm, locale } = useI18n();

// Создаем реактивный объект AboutUs
const AboutUs = reactive({});

// Функция для обновления AboutUs
function updateAboutUs() {
  Object.assign(AboutUs, tm("about_us"));
}

// Следим за изменением языка
watch(locale, () => {
  updateAboutUs();
});

// Инициализируем данные
updateAboutUs();
</script>
