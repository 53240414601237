<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="group-hover:stroke-gray-300 duration-300"
      d="M10.6885 5C8.24973 5 4.99976 8.25 4.99976 10.6875C4.99976 13.8288 8.24976 19.625 11.4998 22.875C11.8508 23.2247 12.7738 24.1503 13.1248 24.5C16.3748 27.75 22.1702 31 25.311 31C27.7498 31 30.9998 27.75 30.9998 25.3125C30.9998 22.875 27.7498 19.625 25.311 19.625C24.4998 19.625 21.5592 21.3059 21.2498 21.25C18.6602 20.7825 15.2906 17.3313 14.7498 14.75C14.6744 14.3896 16.3748 11.5 16.3748 10.6875C16.3748 8.25 13.1247 5 10.6885 5Z"
      stroke="black"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
