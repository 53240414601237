<script setup>
import Paragraph1 from "../../text/Paragraph1.vue";
import Paragraph2_1 from "../../text/Paragraph2_1.vue";
</script>

<template>
  <div class="basis-full px-2 md:basis-6/12 lg:basis-4/12">
    <div class="group relative overflow-hidden rounded-4xl">
      <img
        :src="image"
        :alt="name + ' ' + job"
        class="group-hover:translate-y- transform-gpu transition-transform duration-500 group-hover:scale-110"
      />
      <div
        class="absolute bottom-0 left-1/2 flex h-40 w-full -translate-x-1/2 transform flex-col items-center justify-center space-y-2 rounded-t-full bg-yellow-500 opacity-100 transition-opacity duration-300 group-hover:opacity-100 md:opacity-0"
      >
        <p class="pt-7">
          <Paragraph1>
            {{ name }}
          </Paragraph1>
        </p>
        <p class="pb-4">
          <Paragraph2_1>
            {{ job }}
          </Paragraph2_1>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Paragraph1,
    Paragraph2_1,
  },
  props: {
    name: String,
    job: String,
    image: String,
  },
};
</script>
