<script setup>
import ModalButton from "../buttons/ModalButton.vue";
</script>

<template>
  <ModalButton
    class="border border-black bg-black text-yellow-500 hover:bg-inherit hover:text-black"
  >
    <slot />
  </ModalButton>
</template>

<script>
export default {
  components: {
    ModalButton,
  },
};
</script>
