<script setup>
import Paragraph2 from "../../text/Paragraph2.vue";
import Paragraph3 from "../../text/Paragraph3.vue";
</script>

<template>
  <router-link to="/services" exact
    class="flex flex-row flex-wrap items-center rounded-3xl hover:bg-gray-300 sm:mb-6 md:mb-8 lg:mb-10 xl:mb-8 cursor-pointer"
  >
    <div class="basis-2/12 lg:basis-2/12">
      <img :src="iconSrc" :alt="iconAlt" class="h-auto w-full" />
    </div>
    <div class="basis-10/12 ps-4 lg:basis-10/12">
      <h3>
        <Paragraph3>
          {{ title }}
        </Paragraph3>
      </h3>
      <p>
        <Paragraph2 class="hidden sm:block">
          {{ description }}
        </Paragraph2>
      </p>
    </div>
  </router-link>
  <div class="flex flex-row sm:hidden">
    <div class="mb-6 mt-4 basis-full">
      <p>
        <Paragraph2>
          {{ description }}
        </Paragraph2>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Paragraph2,
    Paragraph3,
  },
  props: {
    iconSrc: String,
    iconAlt: String,
    title: String,
    description: String,
  },
};
</script>
