<template>
  <svg
    width="38"
    height="37"
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="whatsapp-fill"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.047 5.37565C28.586 1.91039 23.9771 0 19.0726 0C8.9638 0 0.735495 8.22419 0.731212 18.3328C0.731212 21.5626 1.57505 24.7195 3.18132 27.4994L0.581299 37L10.3046 34.4513C12.9818 35.912 16.0015 36.6829 19.0685 36.6829H19.077C29.1858 36.6829 37.4142 28.4588 37.4185 18.3458C37.4185 13.4455 35.5122 8.84093 32.0514 5.37565H32.047ZM19.0726 33.5861H19.0685C16.3313 33.5861 13.6498 32.8494 11.3112 31.4616L10.7544 31.1316L4.98461 32.6437L6.52663 27.0197L6.16253 26.4414C4.63765 24.0169 3.83238 21.2113 3.83238 18.3328C3.83238 9.92896 10.6729 3.0926 19.0856 3.0926C23.1591 3.0926 26.9841 4.68174 29.8625 7.56019C32.741 10.4429 34.3259 14.268 34.3216 18.3415C34.3216 26.7455 27.481 33.5818 19.077 33.5818L19.0726 33.5861ZM27.4338 22.1708C26.9756 21.9395 24.7225 20.8343 24.3028 20.6802C23.883 20.526 23.5789 20.4489 23.2704 20.9115C22.9663 21.3697 22.0882 22.4021 21.8184 22.7062C21.5528 23.0104 21.283 23.0489 20.8245 22.8219C20.3663 22.5906 18.8885 22.1108 17.1409 20.5474C15.7787 19.3309 14.8579 17.8317 14.5922 17.3735C14.3268 16.9152 14.5622 16.6667 14.7935 16.4396C14.9992 16.2341 15.252 15.9041 15.4789 15.6387C15.7059 15.3731 15.7831 15.1802 15.9372 14.8763C16.0915 14.5722 16.0144 14.3022 15.8987 14.0752C15.7831 13.8439 14.8663 11.5909 14.4852 10.6742C14.1124 9.77897 13.7355 9.90312 13.4529 9.88613C13.1872 9.8733 12.879 9.86896 12.5748 9.86896C12.2707 9.86896 11.7738 9.98462 11.354 10.4429C10.9342 10.9012 9.75204 12.0107 9.75204 14.2637C9.75204 16.5167 11.3925 18.6969 11.6238 19.0011C11.8509 19.3052 14.8535 23.9356 19.4539 25.9188C20.5461 26.3899 21.4028 26.6727 22.0667 26.8825C23.1632 27.2295 24.1656 27.1823 24.9538 27.0625C25.8362 26.9297 27.6651 25.953 28.0464 24.8823C28.4275 23.8114 28.4275 22.8947 28.3119 22.7019C28.1962 22.5093 27.8921 22.3978 27.4338 22.1665V22.1708Z"
      fill="black"
    />
  </svg>
</template>
