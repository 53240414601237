<script setup>
import BlockSectionTop from "../components/shared/BlockSectionTop.vue";
import BlockSection from "../components/shared/BlockSection.vue";
import Container from "../components/shared/Container.vue";
import Headline1 from "../components/text/Headline1.vue";
import Headline4 from "../components/text/Headline4.vue";
import HrNumber from "../components/shared/HrNumber.vue";
import PortfolioCard from "../components/page/portfolio/PortfolioCard.vue";

import YellowBlackButton from "../components/buttons/Yellow-BlackButton.vue";
import { ref } from "vue";
import ModalForm from "../components/forms/ModalForm.vue";

const PackMyProject = ref(false);
</script>

<template>
  <!-- block - 1 - Headline 1 -->
  <BlockSectionTop />
  <Container>
    <div class="mx-2 flex flex-row">
      <div class="mb-14 basis-full px-2 text-center md:mb-20">
        <h1>
          <Headline1>
            {{ $t("portfolio.h1") }}
          </Headline1>
        </h1>
      </div>
    </div>
  </Container>

  <!-- block - 2 - Portfolio's -->
  <BlockSection v-for="(portfolio, index) in portfolios" :key="index">
    <Container>
      <div class="mx-2 flex flex-row flex-wrap">
        <div class="basis-full px-2">
          <h2 class="mb-3">
            <Headline4>
              {{ portfolio.title }}
            </Headline4>
          </h2>
          <HrNumber />
        </div>
        <PortfolioCard
          v-for="(project, index) in portfolio.projects"
          :key="index"
          :ImageProject="project.ImageProject"
          :NameProject="project.NameProject"
          :LinkWebsite="project.LinkWebsite"
          :LinkProject="project.LinkProject"
          :FormModal="project.FormModal"
        />
        <YellowBlackButton
          @click="PackMyProject = true"
          class="w-full md:hidden"
        >
          {{ $t("portfolio.yellow_block") }}
        </YellowBlackButton>
      </div>
    </Container>
  </BlockSection>

  <ModalForm v-if="PackMyProject" @close="PackMyProject = false">
    <Paragraph1>
      {{ $t("header.pack_my_project") }}
    </Paragraph1>
  </ModalForm>
</template>

<script>
export default {
  components: {
    BlockSection,
    Container,
    Headline1,
    Headline4,
    HrNumber,
    PortfolioCard,
    YellowBlackButton,
    ModalForm,
  },
  computed: {
    portfolios() {
      return [
        {
          title: this.$t("portfolio.vera_projects.h2"),
          projects: [
            {
              ImageProject: "/img/portfolio/vera_fund.webp",
              NameProject: this.$t("portfolio.vera_projects.project_1.title"),
              LinkWebsite: "https://vera.fund/",
              LinkProject: "/portfolio/vera-fund",
            },
            {
              ImageProject: "/img/portfolio/aventura_quattro.webp",
              NameProject: this.$t("portfolio.vera_projects.project_2.title"),
              LinkWebsite: "https://aventuraquattro.com/",
              LinkProject: "/portfolio/aventura-quattro",
            },
            {
              ImageProject: "/img/portfolio/vera_realty.webp",
              NameProject: this.$t("portfolio.vera_projects.project_3.title"),
              LinkWebsite: "https://verarealty.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/coral_ridge.webp",
              NameProject: this.$t("portfolio.vera_projects.project_4.title"),
              LinkWebsite: "https://coralridgetownhomes.com/",
              LinkProject: "/portfolio/coral-ridge",
            },
            {
              ImageProject: "/img/portfolio/vera_tower.webp",
              NameProject: this.$t("portfolio.vera_projects.project_5.title"),
              LinkWebsite: "https://veraofficetower.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/co_living.webp",
              NameProject: this.$t("portfolio.vera_projects.project_6.title"),
              LinkWebsite: "https://aventuracoliving.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/aam.webp",
              NameProject: this.$t("portfolio.vera_projects.project_7.title"),
              LinkWebsite: "https://athletesvera.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/vera_management.webp",
              NameProject: this.$t("portfolio.vera_projects.project_8.title"),
              LinkWebsite: "https://veramanage.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/vera_finder.webp",
              NameProject: this.$t("portfolio.vera_projects.project_9.title"),
              LinkWebsite: "https://verafinder.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/aventura_apartments.webp",
              NameProject: this.$t("portfolio.vera_projects.project_10.title"),
              LinkWebsite: "https://aventuraappartments.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/ce_pony.webp",
              NameProject: this.$t("portfolio.vera_projects.project_11.title"),
              LinkWebsite: "https://www.cepony.com",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/yellow.webp",
              FormModal: "md:block hidden",
            },
          ],
        },
        {
          title: this.$t("portfolio.projects.h2"),
          projects: [
            {
              ImageProject: "/img/portfolio/the_elser.webp",
              NameProject: this.$t("portfolio.projects.project_1.title"),
              LinkWebsite: "https://miamitheelser.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/baccarat.webp",
              NameProject: this.$t("portfolio.projects.project_2.title"),
              LinkWebsite: "https://residencesbaccarat.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/polyus_consulting.webp",
              NameProject: this.$t("portfolio.projects.project_3.title"),
              LinkWebsite: "https://polyusconsulting.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/park_tower.webp",
              NameProject: this.$t("portfolio.projects.project_4.title"),
              LinkWebsite: "https://parktowerturnberry.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/st_regis.webp",
              NameProject: this.$t("portfolio.projects.project_5.title"),
              LinkWebsite: "https://sunnyislesstregisresidence.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/valusso_referral.webp",
              NameProject: this.$t("portfolio.projects.project_6.title"),
              LinkWebsite: "https://valussodesignpro.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/casa_bella.webp",
              NameProject: this.$t("portfolio.projects.project_8.title"),
              LinkWebsite: "https://www.casabellatower.com/",
              LinkProject: "",
            },
            {
              ImageProject: "/img/portfolio/yellow.webp",
              FormModal: "md:block hidden",
            },
          ],
        },
      ];
    },
  },
};
</script>
