<script setup>
import BlockSection from "../../components/shared/BlockSection.vue";
import Container from "../../components/shared/Container.vue";
import YellowBlackButton from "../../components/buttons/Yellow-BlackButton.vue";
import Headline3 from "../../components/text/Headline3.vue";
import Paragraph2_1 from "../../components/text/Paragraph2_1.vue";
import Paragraph3 from "../../components/text/Paragraph3.vue";
import SocialIcons from "../../components/page/portfolio/SocialIcons.vue";
</script>

<template>
  <!-- block - 1 -->
  <BlockSection class="mt-20 md:mt-[80px]">
    <Container>
      <div class="mx-4 flex flex-row flex-wrap justify-between">
        <!-- Заголовки -->
        <div class="mb-8 basis-full md:mb-12 lg:mb-[60px] 2xl:mb-[74px]">
          <div class="flex flex-row">
            <div class="basis-1/2">
              <p>
                <router-link to="/portfolio" exact>
                  <Paragraph2_1 class="hover:underline">
                    <span class="sm:hidden">
                      {{ $t("portfolio.view") }}
                    </span>
                    <span class="lowercase">
                      {{ $t("portfolio.h1") }}
                    </span>
                  </Paragraph2_1>
                </router-link>
                <Paragraph2_1
                  class="invisible lowercase text-gray-300 sm:visible"
                >
                  / {{ top_headline }}
                </Paragraph2_1>
              </p>
            </div>
            <div class="basis-1/2 text-end">
              <p>
                <router-link :to="BackLink">
                  <Paragraph2_1 class="pe-4 hover:underline">
                    {{ $t("portfolio.back") }}
                  </Paragraph2_1>
                </router-link>
                <router-link :to="NextLink" class="hover:underline">
                  <Paragraph2_1>
                    {{ $t("portfolio.next_case") }}
                  </Paragraph2_1>
                </router-link>
              </p>
            </div>
          </div>
        </div>

        <!-- Меню -->
        <div class="basis-12/12 hidden lg:block lg:basis-4/12 xl:basis-3/12">
          <div class="rounded-3xl border border-black p-12">
            <ul class="list-image-[url(/img/portfolio_items/krug.svg)]">
              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] mb-4 whitespace-nowrap pl-6"
              >
                <a href="#menu0" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{ $t("portfolio.menu_items.about_the_project.title") }}
                  </Paragraph3>
                </a>
              </li>
              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] mb-4 whitespace-nowrap pl-6"
              >
                <a href="#menu1" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{ $t("portfolio.menu_items.business_identity.title") }}
                  </Paragraph3>
                </a>
              </li>
              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] mb-4 whitespace-nowrap pl-6"
              >
                <a href="#menu2" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{
                      $t("portfolio.menu_items.web_design_development.title")
                    }}
                  </Paragraph3>
                </a>
              </li>

              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] mb-4 whitespace-nowrap pl-6"
              >
                <a href="#menu3" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{ $t("portfolio.menu_items.crm_integration.title") }}
                  </Paragraph3>
                </a>
              </li>
              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] mb-4 whitespace-nowrap pl-6"
              >
                <a href="#menu4" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{ $t("portfolio.menu_items.social_media_content.title") }}
                  </Paragraph3>
                </a>
              </li>
              <li
                class="ist-image-[url(/img/portfolio_items/krug_hover.svg)] whitespace-nowrap pl-6"
              >
                <a href="#menu5" class="duration-300 hover:underline">
                  <Paragraph3>
                    {{ $t("portfolio.menu_items.youtube_develpment.title") }}
                  </Paragraph3>
                </a>
              </li>
            </ul>
          </div>
          <div class="my-8 flex justify-center">
            <a :href="WebsiteLink" target="_blank">
              <YellowBlackButton>
                {{ $t("buttons.view_the_website") }}
              </YellowBlackButton>
            </a>
          </div>
          <div class="flex justify-evenly">
            <SocialIcons
              :LinkInstagram="SocialLinks.LinkInstagram"
              :LinkLinkedin="SocialLinks.LinkLinkedin"
              :LinkTelegram="SocialLinks.LinkTelegram"
              :LinkFacebook="SocialLinks.LinkFacebook"
              :LinkYoutube="SocialLinks.LinkYoutube"
            />
          </div>
        </div>

        <!-- Контент -->
        <div class="basis-full overflow-hidden lg:basis-7/12 xl:basis-8/12">
          <div v-for="(item, index) in DataItems" :key="index" class="mb-20">
            <div :id="'menu' + index">
              <h1 class="mb-8" v-if="item.headline">
                <Headline3>
                  {{ item.headline }}
                </Headline3>
              </h1>
              <p>
                <Paragraph3>
                  {{ item.content }}
                </Paragraph3>
              </p>
              <img
                :src="item.image"
                alt="item.title"
                :class="item.hover_img"
                class="mt-8 h-auto w-full"
              />
              <!--  Контакты для мобилок -->
              <div
                class="mt-12 flex basis-full flex-row flex-wrap md:mt-16 lg:hidden"
                v-if="item.headline"
              >
                <div class="mb-12 basis-full sm:mb-0 sm:basis-1/2">
                  <a :href="WebsiteLink" target="_blank">
                    <YellowBlackButton>
                      {{ $t("buttons.view_the_website") }}
                    </YellowBlackButton>
                  </a>
                </div>
                <div class="basis-full sm:basis-1/2">
                  <div class="flex justify-around">
                    <SocialIcons
                      :LinkInstagram="SocialLinks.LinkInstagram"
                      :LinkLinkedin="SocialLinks.LinkLinkedin"
                      :LinkTelegram="SocialLinks.LinkTelegram"
                      :LinkFacebook="SocialLinks.LinkFacebook"
                      :LinkYoutube="SocialLinks.LinkYoutube"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  </BlockSection>
</template>

<script>
export default {
  components: {
    BlockSection,
    Container,
    YellowBlackButton,
    Headline3,
    Paragraph2_1,
    Paragraph3,
    SocialIcons,
  },
  props: {
    ProjectName: String,
    BackLink: String,
    NextLink: String,
    WebsiteLink: String,
    LinkInstagram: String,
    LinkLinkedin: String,
    LinkTelegram: String,
    LinkFacebook: String,
    LinkYoutube: String,
  },

  data() {
    return {
      top_headline: this.$t(
        "portfolio.menu_items.about_the_project." +
          this.ProjectName +
          ".headline",
      ),
      BackLink: this.BackLink,
      NextLink: this.NextLink,
      WebsiteLink: this.WebsiteLink,
      SocialLinks: {
        LinkInstagram: this.LinkInstagram,
        LinkLinkedin: this.LinkLinkedin,
        LinkTelegram: this.LinkTelegram,
        LinkFacebook: this.LinkFacebook,
        LinkYoutube: this.LinkYoutube,
      },
      DataItems: [
        {
          headline: this.$t(
            "portfolio.menu_items.about_the_project." +
              this.ProjectName +
              ".headline",
          ),
          content: this.$t(
            "portfolio.menu_items.about_the_project." +
              this.ProjectName +
              ".content",
          ),
          image: "/img/portfolio_items/path_hover.svg",
          hover_img: "hover:scale-x-125 duration-300",
        },
        {
          title: "",
          content: this.$t(
            "portfolio.menu_items.business_identity." +
              this.ProjectName +
              ".content",
          ),
          image:
            "/img/portfolio_items/" +
            this.ProjectName +
            "/business_identity.webp",
        },
        {
          content: this.$t(
            "portfolio.menu_items.web_design_development." +
              this.ProjectName +
              ".content",
          ),
          image: "/img/portfolio_items/" + this.ProjectName + "/web_d&d.webp",
        },
        {
          content: this.$t(
            "portfolio.menu_items.crm_integration." +
              this.ProjectName +
              ".content",
          ),
          image: "/img/portfolio_items/" + this.ProjectName + "/crm.webp",
        },
        {
          content: this.$t(
            "portfolio.menu_items.social_media_content." +
              this.ProjectName +
              ".content",
          ),
          image:
            "/img/portfolio_items/" +
            this.ProjectName +
            "/social_media_content.webp",
        },
        {
          content: this.$t(
            "portfolio.menu_items.youtube_develpment." +
              this.ProjectName +
              ".content",
          ),
          image: "/img/portfolio_items/" + this.ProjectName + "/youtube.webp",
        },
      ],
    };
  },
};
</script>
