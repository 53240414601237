<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
    class="md:group-hover:stroke-yellow-500 duration-300"
      d="M16 30.9999V0.99994M31 15.9999H1"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
