<script setup>
import ModalButton from "../buttons/ModalButton.vue";
</script>

<template>
  <ModalButton
    class="border border-yellow-500 bg-yellow-500 text-black hover:border-white hover:bg-inherit hover:text-white"
  >
    <slot />
  </ModalButton>
</template>

<script>
export default {
  components: {
    ModalButton,
  },
};
</script>
