<script setup>
import Paragraph2 from "../../text/Paragraph2.vue";
import Paragraph3 from "../../text/Paragraph3.vue";
</script>

<template>
  <p>
    <Paragraph2>
      <Paragraph3>
        {{ title }}
      </Paragraph3>
      {{ description }}
    </Paragraph2>
  </p>
</template>

<script>
export default {
  components: {
    Paragraph2,
    Paragraph3,
  },
  props: {
    title: String,
    description: String,
  },
};
</script>
