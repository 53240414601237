<script setup>
import intlTelInput from "intl-tel-input";
import BlackGrayButton from "../buttons/Black-GrayButton.vue";
import Spinner from "../svg/Spinner.vue";
import { ref, onMounted } from "vue";
import Paragraph1 from "../text/Paragraph1.vue";

const name = ref("");
const email = ref("");
const phone = ref("");
const recaptchaToken = ref("");

const tumbler = ref(false);
const isSubmitting = ref(false);

const getRecaptchaToken = async () => {
  return new Promise((resolve, reject) => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute("6LffnfUpAAAAAMSpldmKkHJSmV9RqTTJRVPAJRI8", {
          action: "submit",
        })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
};

const submitForm = async (event) => {
  event.preventDefault();
  isSubmitting.value = true;

  try {
    recaptchaToken.value = await getRecaptchaToken();

    const url = `https://crm.zone24.space/rest/1/vrrkeopa60sdhbvr/crm.lead.add.json?FIELDS[TITLE]=Polyus Studio - ${name.value}&FIELDS[NAME]=${name.value}&FIELDS[EMAIL][0][VALUE]=${email.value}&FIELDS[PHONE][0][VALUE]=${phone.value}&FIELDS[UF_CRM_1707737542]=Polyus Studio&g-recaptcha-response=${recaptchaToken.value}`;

    const response = await fetch(url, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    tumbler.value = true;
    console.log("Success:", data);
  } catch (error) {
    console.error("Error:", error);
  } finally {
    isSubmitting.value = false;
  }
};

const initIntlTelInput = () => {
  const input = document.querySelector("#phone-input");
  const iti = intlTelInput(input, {
    initialCountry: "us",
    allowDropdown: true,
    autoInsertDialCode: true,
    containerClass: "int-tel-container",
    countrySearch: true,
    fixDropdownWidth: false,
    formatAsYouType: true,
    formatOnDisplay: true,
    hiddenInput: () => "phone_full",
    placeholderNumberType: "MOBILE",
    autoFormat: true,
    showFlags: true,
    showSelectedDialCode: true,
    nationalMode: true,
    separateDialCode: true,
    singleDialCode: true,
    utilsScript: "../../../node_modules/intl-tel-input/build/js/utils.js",
  });

  input.addEventListener("blur", () => {
    phone.value = iti.getNumber();
  });
};

onMounted(() => {
  initIntlTelInput();
});
</script>

<template>
  <form @submit="submitForm" class="mt-8">
    <div class="mb-5">
      <input
        v-model="name"
        name="name"
        class="h-12 w-full rounded-3xl border border-black p-4"
        type="text"
        maxlength="29"
        :placeholder="$t('forms.name')"
        required
      />
    </div>
    <div class="mb-5">
      <input
        id="phone-input"
        class="h-12 w-full rounded-3xl border border-black p-4"
        type="tel"
        maxlength="30"
        required
      />
    </div>
    <div class="mb-8">
      <input
        v-model="email"
        class="h-12 w-full rounded-3xl border border-black p-4"
        name="email"
        type="email"
        pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
        :placeholder="$t('forms.email')"
        required
      />
    </div>
    <div class="flex flex-row items-center">
      <div class="basis-1/2 pe-5"></div>
      <div class="basis-1/2 text-end">
        <BlackGrayButton v-if="!tumbler" type="submit" :disabled="isSubmitting">
          <span v-if="!isSubmitting">
            {{ $t("forms.submit") }}
          </span>
          <span v-if="isSubmitting">
            <Spinner />
          </span>
        </BlackGrayButton>
        <p v-if="tumbler" class="px-2">
          <Paragraph1> Successfully</Paragraph1>
        </p>
      </div>
    </div>
  </form>
</template>
