<template>
  <a :href="LinkInstagram" target="_blank" v-if="LinkInstagram">
    <img src="/img/portfolio_items/icon_inst.svg" alt="Instagram Icon" />
  </a>
  <a :href="LinkLinkedin" target="_blank" v-if="LinkLinkedin">
    <img src="/img/portfolio_items/icon_linkedin.svg" alt="Linkedin Icon" />
  </a>
  <a :href="LinkTelegram" target="_blank" v-if="LinkTelegram">
    <img src="/img/portfolio_items/icon_telega.svg" alt="Telegram Icon" />
  </a>
  <a :href="LinkFacebook" target="_blank" v-if="LinkFacebook">
    <img src="/img/portfolio_items/icon_facebook.svg" alt="Facebook Icon" />
  </a>
  <a :href="LinkYoutube" target="_blank" v-if="LinkYoutube">
    <img src="/img/portfolio_items/icon_youtube.svg" alt="Youtube Icon" />
  </a>
</template>

<script>
export default {
  props: {
    LinkInstagram: String,
    LinkLinkedin: String,
    LinkTelegram: String,
    LinkFacebook: String,
    LinkYoutube: String,
  },
};
</script>