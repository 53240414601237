<template>
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="duration-300 group-hover:fill-gray-300"
      d="M17.4984 2.84839C9.444 2.84839 2.91504 9.37764 2.91504 17.4317C2.91504 19.523 3.38169 21.6058 4.28294 23.5385C3.08711 29.7145 2.96026 30.2833 2.96026 30.2833C2.76484 31.2948 3.63398 32.161 4.64752 31.9696C4.64752 31.9696 5.20024 31.8739 11.4375 30.6935C13.3202 31.5884 15.4071 32.0151 17.4984 32.0151C25.5527 32.0151 32.0817 25.4858 32.0817 17.4317C32.0817 9.37764 25.5527 2.84839 17.4984 2.84839ZM17.4984 5.76506C23.9413 5.76506 29.165 10.9884 29.165 17.4317C29.165 23.8751 23.9413 29.0984 17.4984 29.0984C15.6652 29.0984 13.905 28.6679 12.3038 27.8678C12.0179 27.7255 11.7044 27.6719 11.3909 27.7312C6.21381 28.7112 6.62504 28.6527 6.19629 28.7338C6.28087 28.2998 6.20794 28.7503 7.19961 23.6296C7.26086 23.3126 7.20691 22.961 7.06253 22.6727C6.24878 21.0602 5.83171 19.2803 5.83171 17.4317C5.83171 10.9884 11.0555 5.76506 17.4984 5.76506ZM13.3975 10.1401C12.0296 10.1401 10.2067 11.963 10.2067 13.3302C10.2067 15.0921 12.0296 18.3432 13.8525 20.1661C14.0494 20.3622 14.5671 20.8814 14.764 21.0776C16.5869 22.9005 19.8376 24.7234 21.5992 24.7234C22.9671 24.7234 24.79 22.9005 24.79 21.5333C24.79 20.1661 22.9671 18.3432 21.5992 18.3432C21.1442 18.3432 19.4948 19.286 19.3213 19.2546C17.8688 18.9924 15.9788 17.0566 15.6755 15.6088C15.6332 15.4067 16.5869 13.7859 16.5869 13.3302C16.5869 11.963 14.764 10.1401 13.3975 10.1401Z"
      fill="black"
    />
  </svg>
</template>
