<script setup>
import { ref } from "vue";
import { SplideSlide } from "@splidejs/vue-splide";
import YellowBorderWhiteButton from "../buttons/Yellow-BorderWhiteButton.vue";
import Headline3 from "../text/Headline3.vue";
import Paragraph2_1 from "../text/Paragraph2_1.vue";

import { defineEmits } from "vue";

const emit = defineEmits(["open-modal"]);
function openModal() {
  emit("open-modal");
}
</script>
<template>
  <SplideSlide>
    <div
      :class="orderBlockAll"
      class="flex flex-row flex-wrap rounded-4xl bg-gray-300"
    >
      <div
        :class="orderBlock1"
        class="flex basis-full overflow-hidden bg-gray-300 md:basis-1/2"
      >
        <img
          :src="iconSrc"
          class="h-auto w-auto lg:h-[500px] xl:h-[600px] 2xl:h-[750px]"
          :alt="iconAlt"
        />
      </div>

      <div
        :class="orderBlock2"
        class="flex-grow basis-full bg-black lg:basis-1/2"
      >
        <div
          class="flex h-[500px] flex-row items-center justify-center sm:h-[450px] lg:h-[600px] 2xl:h-[833px]"
        >
          <div class="basis-9/12 text-white lg:basis-8/12">
            <div class="">
              <h3>
                <Headline3>
                  {{ title }}
                </Headline3>
              </h3>
              <p class="my-10">
                <Paragraph2_1>
                  {{ description }}
                </Paragraph2_1>
              </p>
              <ul class="my-12 list-outside list-disc">
                <li v-for="(item, index) in li" :key="index">
                  <Paragraph2_1>
                    {{ item }}
                  </Paragraph2_1>
                </li>
              </ul>
              <router-link :to="link" exact v-if="link !== 'ApplyNow'">
                <YellowBorderWhiteButton class="text-nowrap">
                  {{ NameButton }}
                </YellowBorderWhiteButton>
              </router-link>
              <YellowBorderWhiteButton
                @click="openModal"
                v-if="link == 'ApplyNow'"
              >
                <button>
                  {{ $t("buttons.apply_now") }}
                </button>
              </YellowBorderWhiteButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </SplideSlide>
</template>

<script>
export default {
  components: {
    SplideSlide,
    YellowBorderWhiteButton,
  },
  props: {
    title: String,
    description: String,
    link: String,
    NameButton: String,
    iconSrc: String,
    iconAlt: String,
    li: Array,
    orderBlock1: String,
    orderBlock2: String,
    orderBlockAll: String,
  },
};
</script>
